import React from 'react';
import {Route, Router, Routes} from "react-router-dom";
import MyLayout from "./Layout";
import Index from "./pages/Index";
import Video from "./pages/Video";
import Producers from "./pages/Producers";
import Patrons from "./pages/Patrons";
import {Player} from "./pages/Player";
import AdminLayout from "../src/pages/admin/Layout";
import Login from "../src/pages/admin/Login";
import Image from "./pages/admin/Image";
import UpVideo from "./pages/admin/upVideo";
import UpProducers from "./pages/admin/Producers";
import UpPatrons from "./pages/admin/Patrons";
function App() {
  return (
    <>
     <Routes>
         <Route path="/" element={<MyLayout />} >
             <Route index element={<Index/>} />
             <Route path="/video" element={<Video/>} />
             <Route path="/video/player/:id" element={<Player/>} />
             <Route path="/producers" element={<Producers/>} />
             <Route path="/patrons" element={<Patrons/>} />
         </Route>
         <Route path="/w/admin" element={<AdminLayout />} >
             <Route index element={<Image/>} />
             <Route path="video" element={<UpVideo/>} />
             <Route path="login" element={<Login/>} />
             <Route path="producers" element={<UpProducers/>} />
             <Route path="patrons" element={<UpPatrons/>} />
         </Route>
        </Routes>
    </>
  );
}

export default App;
