import {useParams} from "react-router-dom";
import ReactPlayer from "react-player";
import {Breadcrumb} from "antd";
import React, {useState} from "react";
import {getVideoPlayerData} from "../API/getWebDataAPI";

interface VideoPlayerData {
    id: string;
    title: string;
    url: string;
    mediaType: string;
    createdAt: string;
    isDeleted: any
}
export const Player = ()=>{
    const [video,setVideo] = useState<VideoPlayerData>()
    const {id} = useParams()
    getVideoPlayerData(id).then(res=>{
        setVideo(res.data.data)
    }).catch(err=>{
        console.log(err)
    })
    return(
        <>
        <div>
            <a style={
                {
                    textDecoration: "none",
                    color: "#000"
                }} href={"/video"}>
            {"<"+video?.title}</a>
        </div>
        <header className={"App"}>
            <ReactPlayer
                url={video?.url}
                controls
                width={"100%"}
                height={"100%"}
            />
        </header>
        </>
    )

}