import {Button, Checkbox, Form, Input, message} from "antd";
import {LoginApi} from "../../API/getRootDataAPI";
import {NoticeType} from "antd/es/message/interface";
import {adminStore} from "../../store/adminStore";
import {useNavigate} from "react-router-dom";

const Login = () => {
    const navigate = useNavigate()
    const onFinish = (values: any) => {
        LoginApi(values.name, values.password).then((res) => {
            if (res.data.code == -1) {
                alert("用户名或密码错误");
                adminStore.isLogin=false
            }else {
                adminStore.isLogin=true
                console.log(res);
                navigate("/w/admin/");
            }
        }).catch((err) => {
            console.log(err);
            alert("服务器错误");
            adminStore.isLogin=false
        });
    };
    return (
        <div className={"App"}>
            <h1>Login</h1>
            <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{
                    display: 'inline-block', // 使得Form元素成为行内块级元素，以便应用textAlign居中
                    maxWidth: '360px',
                    margin: '0 auto', // 上下外边距为0，左右外边距自动，实现水平居中
                }}
            >
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: '请输入您的名字' }]}
                >
                    <Input placeholder="管理员账户名" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[{ required: true, message: '请输入您的密码' }]}
                >
                    <Input  type="password" placeholder="密码" />
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit">
                       管理员登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
export default Login