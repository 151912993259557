import React from "react";
import "../App.css";
import { Carousel} from "antd";
import ReactPlayer from "react-player";

const Index = () => {

  return (<div>
    <header className={"App"}>
      <h3>🎉欢迎来到韦人学姐的世界🎉</h3>
      <div className={"box"}>
        <Carousel autoplay adaptiveHeight={true}>
          <div>
            <img alt={"img"} src={"/img2.jpg"} className={"img"}/>
          </div>
          <div>
            <img alt={"img"} src={"/img1.jpg"} className={"img"}/>
          </div>
          <div>
            <img alt={"img"} src={"/img3.jpg"} className={"img"}/>
          </div>
        </Carousel>
      </div>
      <h4>🎞️项目视频🎞️</h4>
      <h5>🎞️-1 一起来观看韦人学姐的绝世容颜</h5>
      <ReactPlayer
          url={"https://lizixuan-1306469443.cos.ap-nanjing.myqcloud.com/wrxjFile%2Fvideo%2F1489640883-1-192.mp4"}
          controls
          width={"100%"}
          height={"100%"}
      />
      <h5>🎞️-2 一起跟韦人学姐来到广阔的西部世界</h5>
      <ReactPlayer
          url={"https://lizixuan-1306469443.cos.ap-nanjing.myqcloud.com/wrxjFile%2Fvideo%2F11%E6%9C%8823%E6%97%A5.mp4"}
          controls
          width={"100%"}
          height={"100%"}
      />
    </header>
  </div>);
}
// @ts-ignore
export default Index;