import "./App.css"
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import logo from './logo.svg'
import React, {useEffect, useState} from 'react';
import {Breadcrumb, Layout, Menu, Modal, theme} from 'antd';
import menuData from "./data/menu";
import {adminStore} from "../../store/adminStore";
const { Header, Content, Footer } = Layout;

const items = menuData;


const MyLayout = ()=>{
    const location = useLocation();
    const path = location.pathname; //
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate()
    const showModal = () => {
        setIsModalOpen(true);
    };
    const onClickMenuOne = (e:any) => {
        console.log(e.key);
        navigate(e.key);
    };
    const isLogin = () => {
        if (!adminStore.isLogin){
            navigate("/w/admin/login");
        }
    }
    useEffect(() => {
        isLogin();
    }, []);
    const handleOk = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Layout>
                <Header style={{ display: 'flex', alignItems: 'center',backgroundColor: "white" }}>
                    <div className="logo" >
                        <img src={logo} className={"logo-image"} alt={"logo"} onClick={showModal} title="logo "/>
                        <span className={"logo-text"}>韦人学姐(后台系统)</span>
                    </div>
                    <Menu
                        mode="horizontal"
                        defaultSelectedKeys={[path]}
                        items={items}
                        style={{ flex: 1, minWidth: 0 }}
                        onClick={onClickMenuOne}
                    />
                </Header>
                <Content style={{ padding: '0 48px' }}>
                    <Modal title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleOk}
                           footer={[
                               <button key="submit" type={"button"} onClick={handleOk}>
                                   确定
                               </button>,
                           ]}
                    >
                        <p>作者：<a href={"http://delapouite.com"}>Delapouite</a></p>
                        <p>作品：鹰徽</p>
                        <p>遵循CC BY 3.0许可协议。更多信息请访问：<a
                            href={"https://github.com/game-icons/icons/blob/master/license.txt"}>https://github.com/game-icons/icons/blob/master/license.txt</a>
                        </p>
                        <p>作者：Material Design Authors</p>
                        <p>作品：云</p>
                        <p>遵循Apache 2.0许可协议。更多信息请访问：<a
                            href={"https://github.com/material-icons/material-icons/blob/master/LICENSE"}>https://github.com/material-icons/material-icons/blob/master/LICENSE</a>
                        </p>

                    </Modal>
                    <Breadcrumb style={{margin: '16px 0'}}>
                    </Breadcrumb>
                    <div
                        style={{
                            background: colorBgContainer,
                            minHeight: 280,
                            padding: 24,
                            borderRadius: borderRadiusLG,
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >

                        <header className={"App-header"}>
                            <div className={""}><Outlet/></div>
                        </header>
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    韦人学姐 ©{new Date().getFullYear()} 韦人学姐制作团队
                </Footer>
            </Layout>
        </>

    )
}
export default MyLayout