import React, {useEffect, useState} from "react";
import {Avatar, Divider, Image, List, message, Pagination, Skeleton} from "antd";
import {NoticeType} from "antd/es/message/interface";
import {getImgData, getVideoData} from "../API/getWebDataAPI";
import cloud from "../cloud.png"
import Search from "antd/es/input/Search";
import {SearchProps} from "antd/lib/input";
interface DataType{
    id: number
    title: string
    mediaType: string,
    url: string,
    createdAt: string,
    isDeleted: number
}
const Video = () => {
    const  refScrollbody = React.useRef<HTMLDivElement>(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [pagination, setPagination] = useState({ current: 1, total: 10, pageSize: 5 });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<DataType[]>([]);
    const  [title,setTitle] = useState("");
    const openMessage = (msg: string,type:NoticeType) => {
        messageApi.open({
            type: type,
            content: msg,
        });
    };

    const getVideoList = (pageNum:any) => {
        setLoading(true);
        const axiosR = getVideoData(pageNum,title);
        axiosR.then((res) => {
            console.log(res.data.data);
            if (res.data.code === 1) {
                setData(res.data.data.records);
                const len = res.data.data.total;
                setPagination({
                    ...pagination,
                    total: len,
                    current: pageNum,
                });
                console.log(len);
            } else {
                console.log("获取数据失败");
                openMessage("获取数据失败", "error");
            }
        }).catch((err) => {
            console.log(err);
            openMessage("获取数据失败", "error");
            setLoading(false);
        });
        console.log(pagination.total)
    };
    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        setPagination({ current: 1, total: 10, pageSize: 5 })
        setTitle(value);
        getVideoList(1);
    }
    useEffect(() => {
        getVideoList(1);
    }, []);
    return (<div>
        <Search placeholder="搜索视频" onSearch={onSearch} enterButton />
            <div
                id="scrollableDiv"
                ref={refScrollbody}
                style={{
                    overflow: 'auto',
                    padding: '0 16px',
                    border: '1px solid rgba(140, 140, 140, 0.35)',
                }}
            >
                <List
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                avatar={<Avatar src={cloud} />}
                                title={<a href={"https://wrxj.lidabai.icu/#/video/player/"+item.id}>{item.title}</a>}
                                description={item.createdAt}
                            />
                        </List.Item>
                    )}
                />
                <Pagination align="center" total={pagination.total} defaultCurrent={pagination.current} onChange={getVideoList} hideOnSinglePage={true}  showSizeChanger={false} pageSize={10} />
            </div>
    </div>);
}
export default Video;