const menuData = [
    {
        label: '首页',
        key: '/'
    },
    {
        label: "制作者",
        key: "/producers"
    },
    {
        label: "赞助者",
        key: "/patrons"
    },
];
export default menuData;