import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import {fileUrl, uploadImg} from "../../API/getRootDataAPI";

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const Image = () => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    fileList.forEach((file) => {
      const formData = new FormData();
      formData.append('file', file as FileType);
      setUploading(true);
      uploadImg(formData).then((res) => {
        console.log(res.data)
        if (res.data.code == 1) {
          fileUrl(res.data.data,"IMAGE","IMAGE").then((res) => {
            console.log(res);
            message.success("上传成功");
          }).catch((error) => {
            console.log(error);
          })

        } else {
          console.log(res.data);
        }
      }
    ).catch((error) => {
      console.log(error);
      })
      setUploading(false);
  })};

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
  };

  return (
      <>
        <div className={"App"}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>上传文件</Button>
        </Upload>
        <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Upload'}
        </Button>
      </div>
      </>
  );
};

export default Image;