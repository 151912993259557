import axios from "axios";

axios.defaults.baseURL = 'https://wrxj.sys.lidabai.icu/api';

const LoginApi = (name:string,password:string) => {
    return axios({
        method: 'post',
        url: 'upload/login',
        data: {
            "name":name,
            "password":password
        }
    })
}
const uploadAuthors = (url:string,name:string,bio:string) => {
    return axios({
        method: 'post',
        url: 'upload/add/authors',
        data: {
            "name": name,
            "avatar": url,
            "bio": bio
        }
    })
}
const uploadSponsors = (url:string,name:string,company:string) => {
    return axios({
        method: 'post',
        url: 'upload/add/sponsors',
        data: {
            "company": company,
            "avatar": url,
            "name": name
        }
    })
}
const fileUrl = (url:string,title:string,type:string) => {
    return axios({
        method: 'post',
        url: 'upload/file/url',
        data: {
        "fileType": type,
        "fileTitle": title,
        "fileUrl": url
        }
    })
}
const uploadImg = (file:FormData) => {
  return axios({
    method: 'post',
    url: 'upload/img/file',
    data: file,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}
const uploadVideo = (file:FormData) => {
    return axios({
        method: 'post',
        url: 'upload/video/file',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}
const uploadAvatar = (file:FormData) => {
    return axios({
        method: 'post',
        url: 'upload/avatar/file',
        data: file,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    })
}
export { LoginApi, uploadImg,uploadVideo,uploadAvatar,fileUrl,uploadAuthors, uploadSponsors }