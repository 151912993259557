import axios from "axios";


axios.defaults.baseURL = 'https://wrxj.sys.lidabai.icu/api';

const getImgData = (pageNum : any) => {
  return axios({
    method: 'post',
    url: '/img',
    data: {
      "pageSize":20,
      "pageNum":pageNum
    }
  })
}
const getVideoData = (pageNum : any,title:string) => {
  return axios({
    method: 'post',
    url: '/video',
    data: {
      "pageSize":10,
      "pageNum":pageNum,
      "name":title
    }
  })
}
const getVideoPlayerData = (id : any) => {
  return axios({
    method: 'post',
    url: '/video/player/'+id,
  })
}
const getSponsorData = ()=>{
  return axios({
    method: 'post',
    url: '/sponsor'
  })
}
const getAuthorsData = ()=>{
  return axios({
    method: 'post',
    url: '/author'
  })
}
export { getImgData, getVideoData, getVideoPlayerData, getSponsorData, getAuthorsData }