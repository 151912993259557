import React, {useState} from "react";
import {Button, Form, type GetProp, Input, message, Upload, UploadFile, type UploadProps} from "antd";
import {uploadAuthors, uploadAvatar, uploadSponsors} from "../../API/getRootDataAPI";
import {UploadOutlined} from "@ant-design/icons";
type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
const UpPatrons = () => {
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [url, setUrl] = useState("");
    const handleUpload = (value:any) => {

        const formData = new FormData();
        formData.append('file', fileList[0] as FileType);
        return uploadAvatar(formData).then((res) => {
                console.log(res.data)
                if (res.data.code == 1) {
                    uploadSponsors(res.data.data,value.name,value.bio).then((res) => {
                        message.success("上传成功");
                    }).catch((error) => {
                        console.log(error);
                        message.error("上传失败");
                    })
                } else {
                    console.log(res.data);
                }
            }
        ).catch((error) => {
            console.log(error);
        })
    };
    const props: UploadProps = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        fileList,
    };

    return (
        <div>

            <Form layout="vertical" onFinish={handleUpload} initialValues={{ remember: true }}>
                <Form.Item label={"头像文件"}>
                    <div key={Math.random()}>
                        <Upload {...props}>
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                        </Upload>

                    </div>
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[{ required: true, message: '请输入名字' }]}
                    label="名字"
                >
                    <Input placeholder="名字" />
                </Form.Item>
                <Form.Item
                    name="bio"
                    rules={[{ required: true, message: '请输入介绍' }]}
                    label="介绍"
                >
                    <Input placeholder="介绍" />
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit">
                        上传
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
}
export default UpPatrons;