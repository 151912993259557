import React, {useEffect, useState} from "react";
import {getAuthorsData, getSponsorData} from "../API/getWebDataAPI";
import {Avatar, List} from "antd";
interface DataType {

    "id": number,
    "name": string,
    "company": string,
    "url": string,
    "isDeleted": number
}
const Patrons = () => {
    const  refScrollbody = React.useRef<HTMLDivElement>(null);
    const [data, setData] = useState<DataType[]>([]);
    const getData = ()=>{
        getSponsorData().then(res=>{
            setData(res.data.data);
        }).catch(err=>{
            console.log(err);
        })

    }
    useEffect(() => {
        getData();
    }, []);
    return(
        <div
            id="scrollableDiv"
            ref={refScrollbody}
            style={{
                overflow: 'auto',
                padding: '0 16px',
                border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
        >
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={<Avatar src={item.url} />}
                            title={item.name}
                            description={item.company}
                        />
                    </List.Item>
                )}
            />
        </div>
    );
}
export default Patrons