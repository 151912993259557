const menuData = [
    {
        label: '首页',
        key: '/w/admin/'
    },
    {
        label: "视频",
        key: "/w/admin/video"
    },
    {
        label: "制作者",
        key: "/w/admin/producers"
    },
    {
        label: "赞助者",
        key: "/w/admin/patrons"
    },
];
export default menuData;